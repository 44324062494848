import React, {useState} from 'react';
import './payment-card.css';
import { CardContentPanel, CardDetailsPanel, CardHeaderPanel } from '../../index';
import { getDefaultActiveTab } from '../../../helpers';

function PaymentCard(props) {
  const { invoice, onError } = props;
  const [activeTab, setActiveTab] = useState(getDefaultActiveTab(invoice));

  return (
    <div className={'card-root box-root'}>
      <div className={'content-header box-root flex-flex flex-direction--column'}>
        <CardHeaderPanel invoice={invoice} />
      </div>
      <div className={'box-root'}>
        <CardContentPanel
          invoice={invoice}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          onError={onError}
        />
      </div>
      <div className={'content-footer box-root'}>
        <CardDetailsPanel invoice={invoice} activeTab={activeTab} />
      </div>
    </div>
  );
}

export default PaymentCard;
