import React from 'react';

export const MemoIcon = () => (
  <svg
    aria-hidden='true'
    height='20'
    width='20'
    viewBox='0 0 16 16'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M9 16H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v7h-5a2 2 0 0 0-2 2zm2 0v-5h5z'></path>
  </svg>
);

export default MemoIcon;
