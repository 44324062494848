import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from 'antd';

const CustomThemeProvider = ({ children }) => {
  const { token } = theme.useToken();
  return (
    <ThemeProvider theme={{ antd: token }}>
      {children}
    </ThemeProvider>
  );
};

export { CustomThemeProvider };
