import React from 'react';
import './show-error.css';

function ShowError({ error }) {
  return (
    <div className='show-error'>
      {(error && <span>{error}</span>) || null}
    </div>
  );
}

export default ShowError;
