import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { ThemeProvider } from '../../providers';
import './pay-button.css';

const PayButtonContainer = styled.div`
  .ant-btn-primary {
    background: ${props => props?.theme?.antd?.colorPrimary}!important
  }
`;

export const PayButton = ({ isLoading = false, onClick = () => true }) => {
  return (
    <ThemeProvider>
      <PayButtonContainer className='pay-invoice-button'>
        <Button
          type='primary'
          htmlType='submit'
          name='pay-invoice'
          loading={isLoading}
          onClick={onClick}
        >
          {isLoading ? '' : 'Pay invoice'}
        </Button>
      </PayButtonContainer>
    </ThemeProvider>
  );
}

export default PayButton;
