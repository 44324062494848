import React from 'react';
import './card-header.css';

function CardHeader({
  invoice: {
    invoiceNumber,
    merchant: { name: merchantName = '' } = {},
    customer: { name: customerName = '', email = '' } = {},
    logoUrl = ''
  }
}) {

  return (
    <div className='header-box box-root flex-flex'>
      <div className='header-box-inner box-root flex-flex'>
        {logoUrl ? <img className='card-header-logo-img' alt='#' src={`${logoUrl}`}/> : null}
        <div className='card-header-title'>
          <span className='card-header-title-inner'>Invoice from {merchantName}</span>
        </div>
        <div className='card-header-customer-name'>
          <span>Billed to {customerName || email}</span>
        </div>
        <div className='card-header-invoice-number'>
          <span>Invoice #{invoiceNumber}</span>
        </div>
      </div>
    </div>
  );
}

export default CardHeader;
