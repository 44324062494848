import React from 'react';
import { useParams } from 'react-router-dom';
import { Spin } from 'antd';
import './invoice.css';
import { PaymentCardPanel } from '../../index';
import { useFetch } from './hooks';

const { REACT_APP_OFFICE_API_URL } = process.env;

function Invoice() {
  const { invoiceId } = useParams();
  const { status, data = {}, refetch } = useFetch(`https://${REACT_APP_OFFICE_API_URL}/office-invoices/${invoiceId}/pay-page`, {
    method: 'GET',
    cache: 'no-cache'
  });
  if (Number(data.code) === 404) {
    window.location = 'https://www.seamlesspay.com';
    return null;
  }
  const { merchant: { name: merchantName = '' } = {} } = data;
  const isLoading = !['fetched', 'refetching'].includes(status);

  const handleError = () => refetch();

  return (
    <div className={'hosted-invoice-root'}>
      <div className={'hosted-invoice-world-root box-root flex-flex'}>
        <div className={'--page-head'} />
        <div className={'hosted-invoice-world-main-pane box-root padding-top--32 flex-flex flex-justify-content--center'}>
          <div className={'hosted-invoice-world-content-pane box-root'}>
            <Spin spinning={isLoading} tip='Loading...'>
              <div className={'box-root'} style={{ pointerEvents: 'none' }}>
                <div
                  className={'box-root flex-flex flex-direction--column flex-justify-content--flex-start flex-wrap--nowrap'}
                  style={{ marginLeft: '-32px', marginTop: '-32px' }}
                >
                  {
                    isLoading
                      ? null
                      : [
                        <div
                          key='box-root-1'
                          className='box-root box-hide-if-empty margin-top--32 margin-left--32'
                          style={{ pointerEvents: 'auto' }}
                        >
                          <div className={'box-root padding-bottom--20'}>
                            <PaymentCardPanel invoice={data} onError={handleError} />
                          </div>
                        </div>,
                        <div
                          key='box-root-2'
                          className='box-root box-hide-if-empty margin-top--32 margin-left--32'
                          style={{ pointerEvents: 'auto' }}
                        >
                          <div className={"box-root margin-bottom--32 margin-horizontal--32"} style={{ pointerEvents: 'none' }}>
                            <div
                              className={"box-root flex-flex flex-direction--column flex-justify-content--flex-start flex-wrap--nowrap"}
                              style={{ marginLeft: '-8px', marginTop: '-8px' }}
                            >
                              <div
                                className="box-root box-hide-if-empty margin-top--8 margin-left--8"
                                style={{ pointerEvents: 'auto' }}
                              >
                                <span
                                  className="text-color--default text-align--center text-font-size--14 text-font-weight--medium text-line-height--20 text-numeric-spacing--proportional text-typeface--base text-wrap--wrap text-display--block"
                                >
                                  <span>Invoicing by SeamlessPay</span>
                                </span>
                              </div>
                              <div className="Box-root Box-hideIfEmpty Margin-top--8 margin-left--8"
                                style={{ pointerEvents: 'auto' }}
                              >
                                <span
                                  className="text-color--gray text-align--center text-font-size--14 text-font-weight--regular text-line-height--20 text-numeric-spacing--proportional text-typeface--base text-wrap--wrap text-display--block"
                                >
                                  <span>{merchantName} partners with SeamlessPay to provide secure invoicing and payment processing.</span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ]
                  }
                </div>
              </div>
            </Spin>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Invoice;
