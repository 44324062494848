import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import './app.css';
import { InvoicePage } from './components';

function App() {

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#2563eb'
        }
      }}
    >
      <Router>
        <Routes>
          <Route path="/invoice/:invoiceId" element={<InvoicePage />} />
          <Route
            path='*'
            render={() => {
              window.location = 'https://www.seamlesspay.com';
              return null;
            }}
          />
        </Routes>
      </Router>
    </ConfigProvider>
  );
}

export default App;
