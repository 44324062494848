export const PAYMENT_TAB_CARD = 'card';
export const PAYMENT_TAB_BANK = 'bank';

export const PAYMENT_TYPE_CREDIT = 'CREDIT_CARD';
export const PAYMENT_TYPE_ACH = 'ACH';
export const PAYMENT_TYPES = [
  PAYMENT_TYPE_CREDIT,
  PAYMENT_TYPE_ACH
];

export const PAYMENT_TYPES_TABS_RELATION = {
  [PAYMENT_TYPE_CREDIT]: PAYMENT_TAB_CARD,
  [PAYMENT_TYPE_ACH]: PAYMENT_TAB_BANK
};

export const PAYMENT_BLOCK_INFO_DESCRIPTION_RELATION = {
  [PAYMENT_TYPE_CREDIT]: 'Pay with card',
  [PAYMENT_TYPE_ACH]: 'Pay with bank transfer'
};

export const INVOICE_STATUS_DRAFT = 'Draft';
export const INVOICE_STATUS_OPEN = 'Open';
export const INVOICE_STATUS_PASTDUE = 'PastDue';
export const INVOICE_STATUS_VOIDED = 'Voided';
export const INVOICE_STATUS_PAID = 'Paid';
export const INVOICE_STATUS_UNCOLLECTIBLE = 'Uncollectible';
