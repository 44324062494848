import React, { useEffect, useState } from 'react';
import seamlesspay from '@seamlesspay/web-sdk';
import './card-content.css';
import { getSinglePaymentType, invoiceCanBePaid } from '../../../helpers';
import { BankIcon, CardIcon, TabBank, TabCard, Tabs } from '../../index';
import { INVOICE_STATUS_PAID, PAYMENT_TAB_BANK, PAYMENT_TAB_CARD } from '../../../constants';
import { AmountDue, Paid } from './components';

const {
  REACT_APP_OFFICE_API_URL,
  REACT_APP_MAIN_API_URL,
  REACT_APP_PAN_VAULT_API_URL
} = process.env;

window.seamlesspay = seamlesspay;

function CardContent(props) {
  const {
    invoice = {},
    invoice: {
      amounts: {
        total: amountDue = 0
      } = {},
      paymentDueDate = '',
      paymentDate = '',
      paymentProcessing = false,
      invoiceId = '',
      accessTokens: [
        webSdkAccessToken,
        invoiceApiAccessToken
      ] = [],
      status,
      availablePaymentTypes = [],
      merchant: {
        settings: {
          invoice: {
            surchargeFeeAmount = 0
          } = {}
        } = {}
      } = {},
      downloadAsPDFLink = '',
      downloadReceiptAsPDFLink = ''
    } = {},
    activeTab,
    setActiveTab,
    onError
  } = props;
  const [sdkClient, setSdkClient] = useState(null);

  useEffect(() => {
    fetch(`https://${REACT_APP_OFFICE_API_URL}/office-invoices/${invoiceId}/track`);
  }, [invoiceId]);

  useEffect(() => {
    if (!webSdkAccessToken || !invoiceApiAccessToken) return;

    seamlesspay.client.create({
      tokenizerApi: { url: `https://${REACT_APP_PAN_VAULT_API_URL}` },
      seamlesspayApi: { url: `https://${REACT_APP_MAIN_API_URL}`, accessToken: webSdkAccessToken },
      authorization: webSdkAccessToken
    }).then((client) => setSdkClient(client));

    return () => { };
  }, [webSdkAccessToken, invoiceApiAccessToken]);
  if (!invoiceId || !sdkClient) return null;

  return (
    <div className='content-block'>
      {{
        [INVOICE_STATUS_PAID]: (
          <Paid
            amountDue={amountDue}
            paymentDate={paymentDate}
            downloadAsPDFLink={downloadAsPDFLink}
            downloadReceiptAsPDFLink={downloadReceiptAsPDFLink}
          />
        ),
        // [INVOICE_STATUS_VOIDED]: (<div />),
      }[status] || (
          <AmountDue
            amountDue={amountDue}
            surchargeFee={surchargeFeeAmount}
            availablePaymentTypes={availablePaymentTypes}
            activeTab={activeTab}
            paymentDueDate={paymentDueDate}
            paymentProcessing={paymentProcessing}
          />
        )}
      {invoiceCanBePaid(invoice) && activeTab &&
        <Tabs
          hideHeader={getSinglePaymentType(invoice)}
          onActivate={setActiveTab}
          active={activeTab}
          items={[
            {
              name: PAYMENT_TAB_CARD,
              title: 'Credit / Debit card',
              icon: <CardIcon />,
              context: {},
              getContent: () => (<TabCard sdkClient={sdkClient} invoice={invoice} onError={onError} />)
            },
            {
              name: PAYMENT_TAB_BANK,
              title: 'Bank transfer',
              icon: <BankIcon />,
              context: {},
              getContent: () => (<TabBank sdkClient={sdkClient} invoice={invoice} onError={onError} />)
            }
          ]}
        />
      }
    </div>
  );
}

export default CardContent;
