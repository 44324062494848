import React from 'react';
import { Popover } from 'antd';
import './paid.css';
import { ArrowDownIcon, CheckSuccessfulIcon } from '../../../../index';
import { formatPrice } from '../../../../../helpers';

export const Paid = ({ amountDue, paymentDate, downloadReceiptAsPDFLink, downloadAsPDFLink }) => {
  return (
    <div className='block-info'>
      <div className='block-info-inner'>
        <div className='block-info-paid'>
          <div className='block-info-paid-check'>
            <CheckSuccessfulIcon />
          </div>
          <div className='block-info-paid-title'>
            <span>PAID</span>
          </div>
        </div>
        <div className='block-info-title'>
          <span>${formatPrice(amountDue)} USD paid {(paymentDate && String(paymentDate).split(',').shift()) || 'N/A'}</span>
        </div>
        <div className='block-info-paid-pdf'>
          <Popover
            placement='bottom'
            trigger='click'
            content={(
              <div className='paid-pdf-popover-content'>
                <a href={downloadReceiptAsPDFLink} target='_blank' rel='noreferrer'>Download receipt</a>
                <a href={downloadAsPDFLink} target='_blank' rel='noreferrer'>Download</a>
              </div>
            )}
          >
            <button>
              <ArrowDownIcon /> Download as PDF...
            </button>
          </Popover>
        </div>
      </div>
    </div>
  );
}

export default Paid;
