import { useState, useEffect } from 'react';

const useFetch = (url, options = {}) => {
  const [status, setStatus] = useState('idle');
  const [data, setData] = useState([]);
  const [fetchOptions] = useState(options);
  useEffect(() => {
    if (!url) return;
    const fetchData = async () => {
      setStatus('fetching');
      const response = await fetch(url, fetchOptions);
      const data = await response.json();
      setData(data);
      setStatus('fetched');
    };
    fetchData();
  }, [url, fetchOptions]);

  const refetch = async () => {
    setStatus('refetching');
    const response = await fetch(url, fetchOptions);
    const data = await response.json();
    setData(data);
    setStatus('fetched');
  };

  return { status, data, refetch };
};

export default useFetch;
