import React, { useEffect, useState, useCallback } from 'react';
import seamlesspay from '@seamlesspay/web-sdk';
import './tab-bank.css';
import { PayButton, ShowError } from '../../index';
import { extractErrorMessage, getValidator } from '../../../helpers';
import { INVOICE_STATUS_PAID } from '../../../constants';

const hostedFieldsStyles = {
  '.invalid': { color: '#dc2727' },
  input: {
    color: '#32325d',
    'font-size': '16px'
  }
};

const validationMessages = {
  accountNumber: [
    {
      checker: ({ isEmpty = false }) => isEmpty,
      message: 'Account number is required.'
    },
    {
      checker: ({ isEmpty = false, isValid = false }) => !isEmpty && !isValid,
      message: 'Your account number is invalid.'
    }
  ],
  routingNumber: [
    {
      checker: ({ isEmpty = false }) => isEmpty,
      message: 'Routing number is required.'
    },
    {
      checker: ({ isEmpty = false, isValid = false }) => !isEmpty && !isValid,
      message: 'Routing number is invalid.'
    }
  ]
};

const setRoutingNumberState = (value) => ({
  isEmpty: value.length === 0,
  isValid: value.length === 9 && /^[0-9]+$/.test(value)
});

const validate = getValidator(validationMessages);

const extendHostedFieldsState = (targetState, extendWith) =>
  ({ ...extendWith, ...targetState });

const routingNumberInitialState = {
  value: '',
  state: { isEmpty: true, isValid: false, isPotentiallyValid: true, isFocused: false, disabled: false }
};

const tokenizationAPIErrors = ['HOSTED_FIELDS_FAILED_TOKENIZATION'];

function TabBank(props) {
  const { sdkClient, invoice: { invoiceId, accessTokens = [] }, onError = () => { } } = props;
  const [hostedFields, setHostedFields] = useState(null);
  const [routingNumber, setRoutingNumber] = useState({ ...routingNumberInitialState });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fieldsState, setFieldsState] = useState({
    accountNumber: { isEmpty: true, isValid: false, isPotentiallyValid: true, isFocused: false, disabled: false }
  });
  useEffect(() => {
    if (!sdkClient) return;

    seamlesspay.hostedFields.create({
      paymentType: 'ach',
      client: sdkClient,
      styles: hostedFieldsStyles,
      fields: {
        accountNumber: {
          selector: '#hosted-field-account-number',
          placeholder: 'Account number'
        }
      }
    }).then(instance => {
      instance.on('change', ({ fields }) => setFieldsState(fields));
      setHostedFields(instance)
    }).catch(error => console.error(error));

    return () => { };
  }, [sdkClient]);

  const handleError = useCallback(() => {
    if (hostedFields) {
      hostedFields.teardown();
    }
    setRoutingNumber({ ...routingNumberInitialState });
    onError();
  }, [hostedFields, onError]);

  const accessToken = [...accessTokens].pop();

  return (
    <form>
      <div className='hosted-fields-inputs'>
        <div className='hosted-field-container' id='routing-number'>
          <input
            placeholder='Routing number'
            name='routingNumber'
            maxLength='9'
            value={routingNumber.value}
            onChange={(changeEvent) =>
              setRoutingNumber({
                value: changeEvent.target.value,
                state: setRoutingNumberState(String(changeEvent.target.value))
              })
            }
          />
        </div>
        <div className='hosted-field-container' id='hosted-field-account-number' />
      </div>
      <ShowError error={error} />
      <PayButton
        isLoading={isLoading}
        onClick={async (event) => {
          event.preventDefault();
          if (!hostedFields || !validate(
            extendHostedFieldsState(fieldsState, { routingNumber: routingNumber.state }), setError
          )) return;

          setIsLoading(true);
          setError(null);

          const tokenizeData = {
            routingNumber: routingNumber.value,
            bankAccountType: 'Checking'
          };

          const payload = await hostedFields.tokenize(tokenizeData)
            .catch((tokenizeError) => {
              if (tokenizationAPIErrors.includes(tokenizeError.code)) {
                handleError();
              }
              throw tokenizeError;
            })
            .then(({ token }) => fetch(`/api/pay`,
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${accessToken}`
                },
                body: JSON.stringify({ token, invoiceId })
              }
            ))
            .then(async (result) => {
              const response = await result.json();
              if (!result.ok) {
                handleError();
                throw response;
              }
              return response;
            })
            .catch((error) => {
              setError(extractErrorMessage(error));
              return null;
            });

          setIsLoading(false);

          if (payload && payload.status === INVOICE_STATUS_PAID) {
            window.location.reload(false);
          }
        }}
      />
    </form>
  );
}

export default TabBank;
