import React from 'react';

export const CheckSuccessfulIcon = () => (
  <svg
    className='InlineSVG Icon Icon--sm'
    focusable='false'
    fill='none'
    viewBox='0 0 16 16'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.297 13.213L.293 8.255a1.014 1.014 0 010-1.426.994.994 0 011.414 0l4.294 4.224 8.288-8.258a.994.994 0 011.414 0c.39.394.39 1.033 0 1.426L6.7 13.21a.994.994 0 01-1.402.004z'
      fill='#09825D'
    />
  </svg>
);

export default CheckSuccessfulIcon;
