import React from "react";

export const ArrowDownIcon = () => (
  <svg
    className='InlineSVG Icon InvoiceThumbnail-downloadIcon Icon--sm'
    focusable='false'
    width='13'
    height='12'
    viewBox='0 0 13 12'
    fill='none'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.2499 9.43738L10.6934 5.99389C10.9866 5.70073 11.4619 5.70073 11.755 5.99389C12.0482 6.28706 12.0482 6.76237 11.755 7.05553L7.03023 11.7803C6.73734 12.0732 6.26247 12.0732 5.96957 11.7803L1.24478 7.05553C0.951611 6.76237 0.951611 6.28706 1.24478 5.99389C1.53794 5.70073 2.01325 5.70073 2.30642 5.99389L5.7499 9.43738V0.75C5.7499 0.335786 6.08569 0 6.4999 0C6.91412 0 7.2499 0.335786 7.2499 0.75V9.43738Z'
      fill='black'
      fillOpacity='0.5'
    />
  </svg>
);

export default ArrowDownIcon;

