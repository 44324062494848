import React from 'react';
import './card-details.css';
import { ArrowDownIcon, MemoIcon } from '../../index';
import { formatPrice, invoiceCanBePaid } from '../../../helpers';
import { INVOICE_STATUS_PAID, PAYMENT_TAB_CARD } from '../../../constants';

function CardDetails({
  activeTab,
  invoice = {},
  invoice: {
    paymentProcessing,
    status,
    items = [],
    summaryTaxes = [],
    amounts: {
      total: amountDue = 0,
      surchargeFee: surchargeFeePaid = 0,
    } = {},
    memo = '',
    merchant: {
      name: merchantName,
      email,
      settings: {
        invoice: {
          surchargeFeeAmount = 0
        } = {}
      } = {}
    } = {},
    downloadAsPDFLink = '',
    footer = ''
  }
}) {
  const isPaid = status === INVOICE_STATUS_PAID || paymentProcessing;
  const surchargeFee = activeTab === PAYMENT_TAB_CARD ? (isPaid ? surchargeFeePaid : surchargeFeeAmount) : 0;
  const invoiceTotals = [
    ...summaryTaxes.map(({ taxRateId: id, name, amount, percentage }) => ({
      id,
      content: '',
      name: `${name} (${percentage}%)`,
      amount
    })),
    ...(
      surchargeFee > 0 && activeTab === PAYMENT_TAB_CARD
        ? [
          {
            id: 'surcharge-fee',
            content: '',
            name: `Surcharge fee`,
            amount: surchargeFee
          }
        ]
        : []
    ),
    {
      id: 'amount-due',
      content: (invoiceCanBePaid(invoice) &&
        <a
          type='button'
          className='block-grid-totals-pdf-button'
          href={downloadAsPDFLink}
          target='_blank'
          rel='noreferrer'
        >
          <ArrowDownIcon /> PDF
        </a>
      ),
      name: 'Amount due',
      amount: isPaid ? amountDue : amountDue + surchargeFee
    },
  ];

  return (
    <div className='card-details-container'>
      <div className='card-details-block-grid'>
        <div className='card-details-block-grid-memo'>
          <div className='invoice-memo-block-icon'>
            <MemoIcon />
          </div>
          <div className='invoice-memo-block'>
            <span>{memo}</span>
          </div>
        </div>
        <div
          role='grid'
          tabIndex='0'
          className='card-details-block-grid-container'
        >
          <div
            role='row'
            className='block-grid-header-row'
          >
            <div role='gridcell' className='block-grid-header-cell'>
              <span>Description</span>
            </div>
            <div role='gridcell' className='block-grid-header-cell-flex'>
              <span>Qty</span>
            </div>
            <div role='gridcell' className='block-grid-header-cell-flex'>
              <span>Price</span>
            </div>
            <div role='gridcell' className='block-grid-header-cell-flex'>
              <span>Total</span>
            </div>
          </div>
          <div className='block-grid-items'>
            {items.map(({ id, productName, qty, price, amount }) => (
              <div
                key={`row-${id}`}
                role='row'
                className='block-grid-items-row'
              >
                <div role='gridcell' className='block-grid-item-cell'>
                  <span className='block-grid-item-span'>{productName}</span>
                </div>
                <div role='gridcell' className='block-grid-item-cell cell-flex'>
                  <span>{qty}</span>
                </div>
                <div role='gridcell' className='block-grid-item-cell cell-flex'>
                  ${formatPrice(price)}
                </div>
                <div role='gridcell' className='block-grid-item-cell cell-flex'>
                  <span>${formatPrice(!isNaN(amount) ? amount : qty * price)}</span>
                </div>
              </div>
            ))}
          </div>
          {invoiceTotals.map(({ id, content, name, amount }) => (
            <div
              key={`row-${id}`}
              role='row'
              className='block-grid-items-row block-grid-totals-items-row'
            >
              <div role='gridcell' className='block-grid-item-cell'>{content}</div>
              <div role='gridcell' className='block-grid-item-cell cell-flex'></div>
              <div role='gridcell' className='block-grid-item-cell cell-flex'><span>{name}</span></div>
              <div role='gridcell' className='block-grid-item-cell cell-flex'><span>${formatPrice(amount)}</span></div>
            </div>
          ))}
        </div>
        {!!footer && <div className='card-details-custom-footer'><span>{footer}</span></div>}
      </div>
      <div className='card-details-block-foot'>
        <div className='card-details-block-foot-inner'>
          <span>If you have any questions, contact {merchantName} at <a href={`mailto:${email}`}>{email}</a>.</span>
        </div>
      </div>
    </div>
  );
}

export default CardDetails;
