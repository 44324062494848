import {
  INVOICE_STATUS_PAID,
  INVOICE_STATUS_VOIDED,
  PAYMENT_TYPE_CREDIT,
  PAYMENT_TYPES_TABS_RELATION
} from './constants';

export const formatPrice = (price) =>
  Number(price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

export const getDefaultActiveTab = ({ availablePaymentTypes = [] }) => {
  if (availablePaymentTypes.includes(PAYMENT_TYPE_CREDIT)) return PAYMENT_TYPES_TABS_RELATION[PAYMENT_TYPE_CREDIT];

  return PAYMENT_TYPES_TABS_RELATION[[...availablePaymentTypes].shift()] || null;
};

export const getSinglePaymentType = ({ availablePaymentTypes = [] }) =>
  availablePaymentTypes.length === 1 ? availablePaymentTypes[0] : null

export const invoiceCanBePaid = ({ status, paymentProcessing }) =>
  ![INVOICE_STATUS_PAID, INVOICE_STATUS_VOIDED].includes(status) && !paymentProcessing;

export const extractErrorMessage = ({
  type,
  message,
  details: {
    originalError,
    originalError: { message: originalMessage, errors = [] } = {}
  } = {}
}) => {
  if (type !== 'CUSTOMER' || !originalError) return message;

  return (errors.length > 0 ? `${originalMessage}: ${errors.join(', ')}` : originalMessage);
};

export const getValidator = (validationMessages) =>
  (fieldsState, setError) => {
    const validationMessage = Object.entries(fieldsState)
      .reduce((result, [field, state]) => {
        const { message } = validationMessages[field].find(({ checker }) => checker(state)) || {};

        return result || !message ? result : message;
      }, null);
    if (validationMessage) {
      setError(validationMessage);

      return false;
    }

    return true;
  };

const helpers = {
  formatPrice,
  getDefaultActiveTab,
  getSinglePaymentType,
  invoiceCanBePaid,
  extractErrorMessage,
  getValidator
};

export default helpers;
