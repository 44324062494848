import React, { useState } from 'react';
import styled from 'styled-components';
import './tabs.css';
import { ThemeProvider } from '../../providers';

const getPrimaryColor = props => props?.theme?.antd?.colorPrimary;

const StyledTabs = styled.div`
  .tab-header-active,
  .tab-header-active:hover {
    box-shadow: 0 0 0 2px ${getPrimaryColor};
  }

  .tab-header-active .tab-header-icon svg, .tab-header-active .tab-header-icon svg g {
    fill: ${getPrimaryColor};
  }

  .tab-header-active .tab-header-title  {
    color: ${getPrimaryColor};
  }
`;

const TabHeader = ({
  name,
  active,
  title,
  icon = null,
  onActivate = () => true
}) => (
  <div
    name={name}
    key={name}
    className={`tab-header tab-header-${name} ${active ? 'tab-header-active' : ''}`}
    onClick={onActivate}
  >
    <div className='tab-header-icon'>{icon}</div>
    <div className='tab-header-title'>{title}</div>
  </div>
);

const TabContainer = ({ getContent, name, context }) => (
  <div
    key={name}
    className={`tab-content tab-content-${name}`}
  >
    {getContent(context)}
  </div>
);

function Tabs(props) {
  const {
    items = [],
    active: defaultActive = null,
    onActivate,
    hideHeader
  } = props;
  const [active, setActive] = useState(defaultActive)
  const [tabContainerStyles, setTabContainerStyles] = useState({})

  return (
    <ThemeProvider>
      <StyledTabs>
        {(!hideHeader && <div className='block-pay-invoice-tab-header'>
          {items.map(({ name, ...tabParams }) => (
            <TabHeader
              key={name}
              {...{ name, ...tabParams }}
              active={name === (onActivate ? defaultActive : active)}
              onActivate={() => {
                if (onActivate) {
                  onActivate(name);
                } else {
                  setActive(name);
                }
                setTabContainerStyles({ animation: 'fadeIn ease 0.5s' });
                setTimeout(() => setTabContainerStyles({ animation: 'none' }), 500);
              }}
            />
          ))}
        </div>) || null}
        <div className='block-pay-invoice-tab-container' style={{ ...tabContainerStyles }}>
          <TabContainer {...items.find(({ name }) => name === (onActivate ? defaultActive : active))} />
        </div>
      </StyledTabs>
    </ThemeProvider>
  );
}

export default Tabs;
