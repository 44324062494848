import React from "react";
import './amount-due.css';
import {formatPrice, getSinglePaymentType} from '../../../../../helpers';
import { PAYMENT_BLOCK_INFO_DESCRIPTION_RELATION, PAYMENT_TAB_CARD } from '../../../../../constants';

export const AmountDue = ({ amountDue, paymentDueDate, paymentProcessing, activeTab, surchargeFee = 0, availablePaymentTypes = [] }) => {

  const singlePaymentType = getSinglePaymentType({ availablePaymentTypes });

  const description = (availablePaymentTypes.length > 0 &&
    <span>
      {singlePaymentType
        ? PAYMENT_BLOCK_INFO_DESCRIPTION_RELATION[singlePaymentType]
        : 'How would you like to pay?'
      }
    </span>
  ) || null;

  return (
    <div className='block-info'>
      <div className='block-info-inner'>
        <div className='block-info-title'>
          <span>${
            formatPrice(activeTab === PAYMENT_TAB_CARD && !paymentProcessing ? amountDue + surchargeFee : amountDue)
          } USD due {
            (paymentDueDate && String(paymentDueDate).split(',').shift()) || 'N/A'
          }</span>
        </div>
        <div className='block-info-description'>
          {paymentProcessing ? 'Payment is being processed' : description}
        </div>
      </div>
    </div>
  );
}

export default AmountDue;
