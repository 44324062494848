import React, { useEffect, useState, useCallback } from 'react';
import seamlesspay from '@seamlesspay/web-sdk';
import './tab-card.css';
import { PayButton, ShowError } from '../../index';
import { extractErrorMessage, getValidator } from '../../../helpers';

const cardElementStyles = {
  '.ElementsApp .InputElement.is-invalid': { color: '#dc2727' }
};

const validationMessages = {
  accountNumber: [
    {
      checker: ({ isEmpty = false }) => isEmpty,
      message: 'Card number is required.'
    },
    {
      checker: ({ isEmpty = false, isValid = false }) => !isEmpty && !isValid,
      message: 'Your card number is invalid.'
    }
  ],
  expDate: [
    {
      checker: ({ isEmpty = false }) => isEmpty,
      message: 'Expiration date is required.'
    },
    {
      checker: ({ isEmpty = false, isValid = false }) => !isEmpty && !isValid,
      message: 'Your card\'s expiration date is invalid.'
    }
  ],
  cvv: [
    {
      checker: ({ isEmpty = false }) => isEmpty,
      message: 'CVV is required.'
    },
    {
      checker: ({ isEmpty = false, isValid = false }) => !isEmpty && !isValid,
      message: 'Your card\'s security code is invalid.'
    }
  ],
  postalCode: [
    {
      checker: ({ isEmpty = false, isValid = false }) => !isEmpty && !isValid,
      message: 'Your postal code is invalid.'
    }
  ],
};

const validate = getValidator(validationMessages);

const tokenizationAPIErrors = [
  'CARD_ELEMENT_FAILED_TOKENIZATION'
];

function TabCard(props) {
  const {
    sdkClient,
    invoice,
    invoice: { invoiceId, accessTokens = [] },
    onError = () => { }
  } = props;

  const [cardElement, setCardElement] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [fieldsState, setFieldsState] = useState({
    accountNumber: { isEmpty: true, isValid: false, isPotentiallyValid: true, isFocused: false, disabled: false }
  });

  useEffect(() => {
    if (!sdkClient) return;

    seamlesspay.cardElement.create({
      client: sdkClient,
      styles: cardElementStyles,
      selector: '#card-element-container'
    }).then(instance => {
      instance.on('change', ({ fields }) => setFieldsState(fields));
      setCardElement(instance);
    });
    return () => { };
  }, [sdkClient]);

  const handleError = useCallback(() => {
    if (cardElement) {
      cardElement.teardown();
    }
    onError();
  }, [cardElement, onError]);

  const {
    merchant: {
      settings: {
        invoice: {
          surchargeFeeAmount: surchargeFee = 0
        } = {}
      } = {}
    } = {}
  } = invoice;

  const accessToken = [...accessTokens].pop();

  return (
    <form>
      <div id='card-element-container' className='card-element-container' />
      <ShowError error={error} />
      <PayButton
        isLoading={isLoading}
        onClick={async (event) => {
          event.preventDefault();
          if (!cardElement || !validate(fieldsState, setError)) return;

          setIsLoading(true);
          setError(null);

          const payload = await cardElement.tokenize()
            .catch((tokenizeError) => {
              if (tokenizationAPIErrors.includes(tokenizeError.code)) {
                handleError();
              }
              throw tokenizeError;
            })
            .then(({ token }) => fetch(`/api/pay`,
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${accessToken}`
                },
                body: JSON.stringify({ token, invoiceId, surchargeFee })
              }
            ))
            .then(async (result) => {
              const response = await result.json();
              if (!result.ok) {
                handleError();
                throw response;
              }
              return response;
            })
            .catch((error) => {
              setError(extractErrorMessage(error));
              return null;
            });

          setIsLoading(false);

          if (payload && payload.paymentProcessing) {
            window.location.reload();
          }
        }}
      />
    </form>
  );
}

export default TabCard;
